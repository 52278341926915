<div class="content-toast">
    <ngb-toast *ngFor="let toast of globals.toasts" style="min-width: 300px;">
        <div class="row">
            <div class="col-8">
                <b [class]="toast.color">{{toast.title}}</b>
            </div>
            <div class="col-4">
                <button class="close" (click)="toastService.remove(toast)"><fa-icon [icon]="icons.faClose"></fa-icon></button>
            </div>
        </div>
        <hr>
        <p>{{toast.description}}</p>
        <a [routerLink]="[toast.link]" target="_blank" class="btn btn-default small" (click)="toastService.remove(toast)"><fa-icon [icon]="icons.faExternalLink" class="mr-2"></fa-icon> Ir a la transaccion </a>
    </ngb-toast>
</div>
