import { Component, ChangeDetectorRef, OnInit, Input } from '@angular/core';
import { Globals } from '../globals';
import { faTriangleExclamation, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { EventEmitterService } from 'src/app/services/event-emitter.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
    @Input() breadcrumb1: string;
    @Input() breadcrumb2: string;
    @Input() showBack: boolean;
    @Input() tradeOption: number = 3;
    icons = { faTriangleExclamation, faExclamationCircle };
    constructor(
        public globals: Globals,
        private cdr: ChangeDetectorRef,
        public translate: TranslateService,
        private router: Router,
        private eventEmitterService: EventEmitterService,
    ) {
        /* this.router.events
            .filter(event => event instanceof NavigationEnd)
            .map(() => this.activatedRoute)
            .map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            })
            .filter(route => route.outlet === 'primary')
            .mergeMap(route => route.data)
            .subscribe(event => {
                let slash = '/';
                if (event.urls[0].title === ' ') {
                    slash = ' ';
                }
                this.breadcrumb = this.translate.instant(event.title) + slash + this.translate.instant(event.urls[0].title);
                cdr.detectChanges();
            }); */
    }
    ngOnInit() {
        this.eventEmitterService.updateDataGlobal.subscribe(() => {
            this.cdr.detectChanges();
        });
    }

    changeGlobalTradeOption(option: number) {
        this.globals.globalTradeOption = option;
    }

    getStyles() {
        const hasOpenPosts = this.globals.openPosts.length > 0 && this.globals.showOpenPosts;
        const hasDisputePosts = this.globals.disputePosts.length > 0 && this.globals.showDisputePosts;

        let styles: any = { 'margin-bottom': '14px' };

        if (hasOpenPosts || hasDisputePosts) {
            styles = {
                ...styles,
                'margin-top': hasOpenPosts && hasDisputePosts ? '110px' : '50px',
                'padding-top': '10px'
            };
        }

        return styles;
    }

}
