import { Injectable } from '@angular/core';
import { Globals } from '../shared/globals';
import { Router } from '@angular/router';
import { DataUser } from '../models/user_model';
import { EventEmitterService } from './event-emitter.service';
import { Title } from '@angular/platform-browser';
@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private timeout;
    public count = true;
    constructor(
        public router: Router,
        public globals: Globals,
        private eventEmitterService: EventEmitterService,
        private titleService: Title,
    ) { }
    public logout() {
        this.globals.notificationContent = [];
        this.globals.notification = 0;
        this.globals.showMenu = 0;
        this.globals.subject.next({
            type: 0,
            body: 'logout',
            id: this.globals.tokenCompleted,
            id_sender: this.globals.dataUser?._id,
            type_user: 2,
            username: this.globals.dataUser?.username
        });
        localStorage.removeItem('tokenTime');
        localStorage.removeItem('token');
        localStorage.removeItem('dataUser');
        localStorage.removeItem('toasts');
        //localStorage.clear();
        sessionStorage.clear();
        this.globals.dataUser = null as any;

        this.globals.openPosts = [];
        this.globals.disputePosts = [];
        this.globals.numberOpenPosts = 0;
        this.globals.numberDisputePosts = 0;

        this.eventEmitterService.updateDataGlobal.emit();
        this.eventEmitterService.logout.emit();

        this.router.navigate(['/']);
    }
    public notificationNative(test: string) {
        // Let's check if the browser supports notifications
        if (!('Notification' in window)) {
            alert('This browser does not support desktop notification');
        } else if (Notification.permission === 'granted') {
            // If it's okay let's create a notification
            const notification = new Notification(test);
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission((permission) => {
                // If the user accepts, let's create a notification
                if (permission === 'granted') {
                    const notification = new Notification(test);
                }
            });
        }
    }
    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }
    public blink(msg: string): void {
        this.count = true;
        const prevTitle = 'LocalCoiners';
        const step = () => {
            const newTitle = this.titleService.getTitle() === prevTitle ?
                msg : prevTitle;
            this.titleService.setTitle(newTitle);
            if (this.count) {
                this.timeout = setTimeout(step.bind(this), 1000);
            } else {
                this.titleService.setTitle(prevTitle);
            }
        };
        clearTimeout(this.timeout);
        step();
    }
}
