import { Component, OnInit, OnDestroy, HostListener, AfterViewInit, Renderer2, ChangeDetectorRef } from '@angular/core';
import { ConnectApiService } from './services/connect-api.service';
import { WebsocketService } from './services/websocket.service';
import { SessionService } from './services/session.service';
import { Observable, timer } from 'rxjs';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import * as momentZone from 'moment-timezone';
import { Globals } from './shared/globals';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { LoginComponent } from './authentication/login/login.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from './services/event-emitter.service';
import { SwUpdate } from '@angular/service-worker';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    sessionTime: number;
    time: ReturnType<typeof setTimeout>;
    alive = true;

    constructor(
        public translate: TranslateService,
        private connectApi: ConnectApiService,
        private sessionLog: SessionService,
        public globals: Globals,
        public router: Router,
        private cookieService: CookieService,
        private modalService: NgbModal,
        private renderer: Renderer2,
        private connectWebsocket: WebsocketService,
        private eventEmitterService: EventEmitterService,
        private swUpdate: SwUpdate
    ) {
        window.addEventListener('storage', (e) => {
            this.globals.toasts = JSON.parse(localStorage.getItem('toasts'));
        });
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                if (confirm('New version available. Load new version?')) {
                    window.location.reload();
                }
            });
        }
        if (!localStorage.getItem('lang')) {
            const currentLanguage = this.translate.getBrowserLang();
            if (this.globals.languages.map((e: any) => e.key).indexOf(currentLanguage) < 0) {
                this.translate.setDefaultLang('en');
                this.translate.use('en');
                localStorage.setItem('lang', 'en');
            } else {
                this.translate.setDefaultLang(currentLanguage);
                this.translate.use(currentLanguage);
                localStorage.setItem('lang', currentLanguage);
            }
        } else {
            this.translate.use(localStorage.getItem('lang'));
        }
        this.globals.activeLang = localStorage.getItem('lang');
        this.globals.textLang =
            this.globals.languages[this.globals.languages.map((e: any) => e.key).indexOf(localStorage.getItem('lang'))].title;
        if (!localStorage.getItem('skipData')) { localStorage.setItem('skipData', 'true'); }
        if (!this.cookieService.get('AutoritationBrowser')) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789' + new Date().getTime().toString();
            const charactersLength = characters.length;
            for (let i = 0; i < 31; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            this.cookieService.set('AutoritationBrowser', result, 15, '/', '', true, "Strict");
        }
        timer(0, 25000)
            .subscribe(() => {
                if (localStorage.getItem('token')) {
                    // todays date
                    const now = momentZone(new Date()).tz('America/New_York');
                    // another date
                    const end = moment(moment.unix(parseInt(localStorage.getItem('tokenTime'), 10)).format('YYYY-MM-DD HH:mm:ss'));
                    const duration = moment.duration(now.diff(end));
                    const minutes = duration.asMinutes() * -1;
                    if (minutes < 0) {
                        Swal.close();
                        this.sessionLog.logout();
                    } else if (minutes <= 2) {
                        /* Swal.fire({
                            title: 'Your session is about to expire',
                            text: 'do you want to renew it?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Yes',
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.value) {
                                if (this.globals.tokenCompleted !== '') { */
                        this.connectApi.refreshToken().subscribe(() => { });
                        // }
                        /* } else {
                            this.sessionLog.logout();
                        }
                    }); */
                    }
                }
            });
    }
    ngAfterViewInit(): void {
        let loader = this.renderer.selectRootElement('#localcoinerpreloader');
        this.renderer.setStyle(loader, 'display', 'none');
    }
    ngOnInit() {
        // webSocket connection
        this.connectWebsocket.runWebsocket();
        if (this.globals.tokenCompleted !== '') {
            this.requestApiFirst();
        } else {
            this.eventEmitterService.successConnectSocket.subscribe(() => {
                this.requestApiFirst();
            });
        }
        this.resetTimer();
    }
    ngOnDestroy(): void {
        this.alive = false; // switches your IntervalObservable off
    }
    @HostListener('document:mousemove')
    @HostListener('document:keypress')
    @HostListener('document:click')
    @HostListener('document:wheel')
    resetTimer() {
        if (this.globals.dataUser && this.globals.dataUser._id) {
            if (this.globals.dataUser.config?.session_time === undefined) {
                this.globals.dataUser.config.session_time = 28800;
            }
            clearTimeout(this.time);
            this.time = setTimeout(() => {
                if (localStorage.getItem('token')) {
                    this.sessionLog.logout();
                    Swal.fire({
                        title: this.translate.instant('advertise.text286'),
                        icon: 'warning',
                        text: this.translate.instant('advertise.text287'),
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#3085d6',
                        allowOutsideClick: false
                    }).then(() => {
                        this.modalService.open(LoginComponent);
                    });
                }
            }, (this.globals.dataUser.config.session_time * 1000));
        }
    }
    requestApiFirst() {
        // obtener el monto minimo para publicar
        this.connectApi.getMinWallet().subscribe((res: any) => {
            this.globals.minWallet = res.content.config.min_amount;
        });
        this.connectApi.getFees().subscribe((res: any) => {
            this.globals.fees = res.content.config;
        });
        this.connectApi.listAllCountries().subscribe((res: any) => {
            let i = 0;
            let Arr_1 = [];
            let Arr_2 = [];
            Arr_1 = res.content;
            Arr_1.sort((a, b) => {
                if (a.name > b.name) {
                    Arr_2[i] = a;
                    i++;
                    return -1;
                } else {
                    if (a.name < b.name) {
                        Arr_2[i] = a;
                        i++;
                        return 1;
                    } else {
                        Arr_2[i] = a;
                        i++;
                        return 0;
                    }
                }
            });

            this.globals.listCountries = Arr_2;
            res.content.sort((a, b) => (a.code > b.code) ? 1 : -1);
            this.globals.listCodePhone = res.content;
        });
    }
}
