import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { ToastComponent } from './shared/toast/toast.component';
import { Globals } from './shared/globals';
import { JwtModule } from '@auth0/angular-jwt';
import { InterceptorDeviceIDClass } from './shared/deviceid-interceptor-class';
import { InterceptorErrosClass } from './shared/erros-interceptor-class';
import { LoaderInterceptor } from './shared/loader-interceptor-class';
import { EventEmitterService } from './services/event-emitter.service';
import { NotFoundComponent } from './404/not-found.component';
import { CookieService } from 'ngx-cookie-service';
import { LayoutsModule } from './layouts/layouts.module';
import { RouterModule } from '@angular/router';
export function jwtTokenGetter() {
    return localStorage.getItem('token');
}


// Translation
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
@NgModule({
    declarations: [
        AppComponent,
        LoadingComponent,
        ToastComponent,
        NotFoundComponent,
    ],
    imports: [
        FontAwesomeModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        NgbModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: jwtTokenGetter,
                allowedDomains: [
                    'localhost:8080',
                    'app.waxxis.com',
                    'app.mylocalcoin.io',
                    'api.dev.mylocalcoin.io',
                    'testapi.mylocalcoin.io'
                ],
                disallowedRoutes: [
                    'dev.mylocalcoin.io/user/signin',
                    'dev.mylocalcoin.io/user/checksignup',
                    'dev.mylocalcoin.io/user/signup',
                    'beta.mylocalcoin.io/user/signin',
                    'beta.mylocalcoin.io/user/checksignup',
                    'beta.mylocalcoin.io/user/signup',
                    'angular15.mylocalcoin.io/user/signin',
                    'angular15.mylocalcoin.io/user/checksignup',
                    'angular15.mylocalcoin.io/user/signup',
                ]
            }
        }),
        LayoutsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        //NgxMaskModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        RouterModule
    ],
    providers: [
        Globals,
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorDeviceIDClass, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorErrosClass, multi: true },
        EventEmitterService,
        CookieService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
