<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="row"  [ngStyle]="getStyles()">
    <div class="col-12 d-flex justify-content-between align-items-center">
        <p class="m-0" style="font-family: Roboto, sans-serif !important; font-size: 16px; color: #54667a;">
            <span style="font-weight: bold;" [innerHTML]="breadcrumb1"></span>
            <span [innerHTML]="breadcrumb2"></span>
        </p>
        <a [routerLink]="'/public'" class="btn btn-primary-local-2" *ngIf="tradeOption === 1 || tradeOption === 2" (click)="changeGlobalTradeOption(tradeOption)">
            {{ tradeOption === 1 ? ('breadcrumbs.text35' | translate) : ('breadcrumbs.text36' | translate) }}
        </a>
    </div>
</div>
<div class="row mb-2 mt-5" *ngIf="globals.dataUser != undefined && globals.dataUser.identity && globals.dataUser.identity.level == 0">
    <div class="col-12">
        <ngb-alert [dismissible]="false" type="danger">
            <fa-icon [icon]="icons.faTriangleExclamation"></fa-icon>
            {{'advertise.text333' | translate}}
            <a class="subbar" [routerLink]="'/settings/'">
                {{'advertise.text334' | translate}}
            </a>
        </ngb-alert>
    </div>
</div>
<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
