import { Injectable } from '@angular/core';
import { DataUser, ContentNotification, Fees } from '../models/user_model';
import { MSGDispute } from '../models/time-real';
import { webSocket } from 'rxjs/webSocket';
import { environment } from '../../environments/environment';
@Injectable()
export class Globals {
    toasts: any[] = localStorage.getItem('toasts') ? JSON.parse(localStorage.getItem('toasts')) : [];
    listCountries: any;
    listCodePhone = [];
    activeLang = '';
    textLang = '';
    languages = [
        { title: 'nav.text20', key: 'en' },
        { title: 'nav.text21', key: 'es' },
    ];
    dataUser: DataUser = JSON.parse(localStorage.getItem('dataUser'));
    fees: Fees = {
        deposit: 0,
        retirement: 0,
        transaction: 0,
        trade_buyer: 0,
        trade_seller: 0,
        deductible_percentage: 0,
    };
    minWallet = 0;
    showMenu = 1;
    chatSupport: any = [];
    activeNotification = '';
    receiverChatSupport = '';
    chatDispute: MSGDispute[] = [];
    notificationSupport = false;
    idChatSupport = '';
    tokenCompleted = '';
    notification = 0;
    notificationContent: Array<ContentNotification> = [];
    price: number;
    percentage: number;
    subject = webSocket(environment.apiWebsocket);

    openPosts: Array<string> = [];
    disputePosts: Array<string> = [];
    numberOpenPosts: number;
    numberDisputePosts: number;
    showOpenPosts: boolean = true;
    showDisputePosts: boolean = true;
    // paginador del dashboard
    statusNavigation = 2;
    pageOpen = 1;
    sizeOpen = 10;
    longOpen = 0;
    pageAdvertisements = 1;
    sizeAdvertisements = 10;
    longAdvertisements = 0;
    pageCompleted = 1;
    sizeCompleted = 10;
    longCompleted = 0;
    pageCancelled = 1;
    sizeCancelled = 10;
    longCancelled = 0;
    pageDispute = 1;
    sizeDispute = 10;
    longDispute = 0;
    pageExpires = 1;
    sizeExpires = 10;
    longExpires = 0;
    searchOpen = '';
    searchAdvertisements = '';
    searchCompleted = '';
    searchCancelled = '';
    searchDispute = '';
    searchExpires = '';
    redirectBack = 1;
    showChatSupport = false;
    loginAfter2FA = false;
    globalTradeOption: number = 2;

    /**
     * Reemplaza una cadena con la ruta de la imagen
     * por otra donde se obtiene el thumbnail de la misma.
     * @param img string
     */
    public Thumbnail(img: any): string {
        if (img.substr(-3).toLowerCase() !== 'svg') {
            let result = '';
            const imgA = img.split('/');
            const file = imgA.pop();
            imgA.forEach((item, index) => {
                if (item !== '') {
                    result += '/' + item;
                }
            });
            img = environment.imgServer + result + '/Thumbnail-' + file;

        }
        return img;
    }

    /**
     *
     * @param value
     * @returns
     */
    public TruncateTo2Dec(value: number): number {
        return Math.floor(value * 100) / 100;
    }
}
