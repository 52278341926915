import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { SessionService } from '../../services/session.service';
import { ConnectApiService } from '../../services/connect-api.service';
import { ToastService } from '../../services/toast.service';
import Swal from 'sweetalert2';
import { EventEmitterService } from '../../services/event-emitter.service';
import { SubscriptionLike } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessagesSocket, NotificationSocket } from '../../models/time-real';
import { Location } from '@angular/common';
import { LoginComponent } from '../../authentication/login/login.component';
import { SignupComponent } from '../../authentication/signup/signup.component';
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faBell, faAddressCard, faUser, faStar } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faBars, faPowerOff, faEarth, faRightToBracket, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, AfterViewInit, OnDestroy {
    icons = { faChevronDown, faFacebookF, faInstagram, faTwitter, faLinkedinIn, faBell, faAddressCard, faBars, faUser, faStar, faPowerOff, faEarth, faRightToBracket, faArrowUp, faArrowDown };
    @ViewChild('priceBTC') priceBTC: ElementRef<HTMLParagraphElement>;
    @ViewChild('percentageBTC') percentageBTC: ElementRef<HTMLParagraphElement>;
    @ViewChild('content') content;
    name: string;
    showHide: boolean;
    statusPercentageBTC = false;
    subscriptionEmitter: SubscriptionLike;
    subscriptionEmitter1: SubscriptionLike;
    subscriptionEmitter2: SubscriptionLike;
    subscriptionEmitter3: SubscriptionLike;
    subscriptionEmitter4: SubscriptionLike;
    subscriptionEmitter5: SubscriptionLike;
    updateForm: UntypedFormGroup;
    numberPhone: any;
    public latitude: number;
    public longitude: number;
    listCountries: any;
    remember = '';
    isCollapsed = true;
    openSubmenu = false;
    public innerWidth: number;
    constructor(
        public router: Router,
        public globals: Globals,
        public sessionLog: SessionService,
        private connectApi: ConnectApiService,
        private toast: ToastService,
        private eventEmitterService: EventEmitterService,
        private cdr: ChangeDetectorRef,
        private modalService: NgbModal,
        private formBuilder: UntypedFormBuilder,
        public translate: TranslateService,
        private location: Location,
    ) {
        this.showHide = true;
    }
    openModal_l() {
        this.modalService.open(LoginComponent);
    }
    openModalRegister() {
        this.modalService.open(SignupComponent);
    }
    changeShowStatus() {
        this.showHide = !this.showHide;
    }
    addExpandClass(element: any) {
        if (element !== this.globals.showMenu) {
            this.globals.showMenu = element;
            this.cdr.detectChanges();
        }
    }
    openModal() {
        this.modalService.open(this.content, { centered: true });
    }
    ngOnInit() {
        this.innerWidth = window.innerWidth;
        if (this.innerWidth < 990) this.isCollapsed = false;
        this.updateForm = this.formBuilder.group({
            first_name: null,
            last_name: null,
            address: null,
            country: null,
            phone_code: null,
            phone_number: null,
        });
        if (this.globals.dataUser !== undefined && this.globals.dataUser !== null) {
            this.getNotification();
        }
        this.subscriptionEmitter2 = this.eventEmitterService.updateStatus.subscribe((data: MessagesSocket) => {
            this.globals.dataUser.status = Number(data.body);
            localStorage.setItem('dataUser', JSON.stringify(this.globals.dataUser));
            this.cdr.detectChanges();
        });
        this.subscriptionEmitter2 = this.eventEmitterService.updateAmountWallet.subscribe((data: MessagesSocket) => {
            this.globals.dataUser.amount_wallet = Number(data.body);
            localStorage.setItem('dataUser', JSON.stringify(this.globals.dataUser));
            this.cdr.detectChanges();
        });
        this.subscriptionEmitter3 = this.eventEmitterService.addNotification.subscribe((data: MessagesSocket) => {
            if (this.globals.dataUser.config.web_notifications) {
                this.sessionLog.notificationNative(this.translate.instant('advertise.text267'));
            }
            const start: NotificationSocket = JSON.parse(data.body);
            if (start.typeNotification === 1) { // transferencia directa
                this.globals.notificationContent.unshift({
                    _id: start.idNotification,
                    title: this.translate.instant('advertise.text243'),
                    description: this.translate.instant(start.message, { amount: start.item }),
                    created_at: Date.now(),
                    type_notification: start.typeNotification,
                    id_subject: data.id_trans,
                    status: false,
                });
                this.resetNotification();
            } else if (start.typeNotification === 2) { // nueva transaccion
                this.globals.notification++;
                this.sessionLog.blink(`${this.translate.instant('advertise.text267')}`);
                this.playSound();
                start.message.split('*')[0];
                const setnotification = {
                    _id: start.idNotification,
                    title: this.translate.instant('advertise.text178'),
                    description: this.translate.instant(start.message.split('*')[0], { username: start.message.split('*')[1], publication: start.message.split('*')[2] }),
                    created_at: Date.now(),
                    type_notification: start.typeNotification,
                    id_subject: data.id_trans,
                    status: false,
                };
                this.toast.show({ id: start.idNotification, color: 'toast-success', title: this.translate.instant('NewTransactionTitle'), description: this.translate.instant(start.message.split('*')[0], { username: start.message.split('*')[1], publication: start.message.split('*')[2] }), link: '/public/transaction/' + data.id_trans });
                this.globals.notificationContent.unshift(setnotification);
            } else if (start.typeNotification === 3) { // transaccion completada
                this.eventEmitterService.finishTransaction.emit(3);
                this.globals.notification++;
                this.sessionLog.blink(`${this.translate.instant('advertise.text267')}`);
                this.playSound();
                const setnotification = {
                    _id: start.idNotification,
                    title: this.translate.instant('advertise.text235'),
                    description: this.translate.instant(start.message, { id: data.id }),
                    created_at: Date.now(),
                    type_notification: start.typeNotification,
                    id_subject: data.id,
                    status: false,
                };
                this.toast.show({ id: start.idNotification, color: 'toast-success', title: this.translate.instant('advertise.text235'), description: this.translate.instant(start.message, { id: data.id }), link: '/public/transaction/' + data.id });
                setnotification.status = true;
                this.globals.notificationContent.unshift(setnotification);
            } else if (start.typeNotification === 4) { // transaccion entro en disputa
                const setnotification = {
                    _id: start.idNotification,
                    title: this.translate.instant('advertise.text242'),
                    description: this.translate.instant(start.message),
                    created_at: Date.now(),
                    type_notification: start.typeNotification,
                    id_subject: data.id_trans,
                    status: false,
                };
                this.globals.notificationContent.unshift(setnotification);
                this.resetNotification();
                this.eventEmitterService.updateStatusTransaction.emit(4);
            } else if (start.typeNotification === 5) { // notificacion de SOPORTE DE CHAT
                const pos = this.globals.notificationContent.map((e: any) => e._id).indexOf(start.idNotification);
                if (pos >= 0) {
                    this.globals.notificationContent[pos].description =
                        this.globals.notificationContent[pos].description + '\n' + start.message;
                    this.globals.notificationContent[pos].status = false;
                    this.move(pos, 0);
                } else {
                    this.globals.notificationContent.unshift({
                        _id: start.idNotification,
                        title: this.translate.instant('body.text216'),
                        description: start.message,
                        created_at: Date.now(),
                        type_notification: start.typeNotification,
                        id_subject: '',
                        status: false,
                    });
                }
                this.playSound();
                this.resetNotification();
            } else if (start.typeNotification === 6) { // transaccion cancelada
                this.eventEmitterService.finishTransaction.emit(6);
                this.globals.notification++;
                this.sessionLog.blink(`${this.translate.instant('advertise.text267')}`);
                this.playSound();
                const setnotification = {
                    _id: start.idNotification,
                    title: this.translate.instant('advertise.text179'),
                    description: this.translate.instant(start.message),
                    created_at: Date.now(),
                    type_notification: start.typeNotification,
                    id_subject: data.id_trans,
                    status: false,
                };
                this.toast.show({ id: start.idNotification, color: 'toast-info', title: this.translate.instant('advertise.text179'), description: this.translate.instant(start.message), link: '/public/transaction/' + data.id_trans });
                this.globals.notificationContent.unshift(setnotification);
            } else if (start.typeNotification === 7) { // notificacion de mensajes nuevos del chat
                const pos = this.globals.notificationContent.map((e: any) => e._id).indexOf(start.idNotification);
                if (pos >= 0) {
                    this.globals.notificationContent[pos].description =
                        this.globals.notificationContent[pos].description + '\n' + start.message;
                    this.globals.notificationContent[pos].status = false;
                    this.move(pos, 0);
                } else {
                    this.globals.notificationContent.unshift({
                        _id: start.idNotification,
                        title: this.translate.instant('advertise.text245'),
                        description: start.message,
                        created_at: Date.now(),
                        type_notification: start.typeNotification,
                        id_subject: data.id_trans,
                        status: false,
                    });
                }
                this.resetNotification();
            } else if (start.typeNotification === 8) { // notificacion de publicacion deshabilitada
                const pos = this.globals.notificationContent.map((e: any) => e._id).indexOf(start.idNotification);
                if (pos >= 0) {
                    /* this.globals.notificationContent[pos].description =
                        this.globals.notificationContent[pos].description + '\n' + this.translate.instant(start.message);
                    this.globals.notificationContent[pos].status = false; */
                    this.move(pos, 0);
                } else {
                    this.globals.notificationContent.unshift({
                        _id: start.idNotification,
                        title: this.translate.instant('advertise.text244', { id: data.id_trans }),
                        description: this.translate.instant(start.message),
                        created_at: Date.now(),
                        type_notification: start.typeNotification,
                        id_subject: data.id_trans,
                        status: false,
                    });
                }
                this.resetNotification();
            } else if (start.typeNotification === 9) { // notificacion de pago
                this.globals.notificationContent.unshift({
                    _id: start.idNotification,
                    title: this.translate.instant('PaymentMade'),
                    description: this.translate.instant('advertise.text168'),
                    created_at: Date.now(),
                    type_notification: 9,
                    id_subject: data.id_trans,
                    status: false,
                });
                this.eventEmitterService.notifiPayment.emit(data);
                this.resetNotification();
                this.playSound();
            } else if (start.typeNotification === 10) { // cancelada por limite de tiempo
                this.eventEmitterService.finishTransaction.emit(2);
                this.globals.notification++;
                this.sessionLog.blink(`${this.translate.instant('advertise.text267')}`);
                this.playSound();
                const setnotification = {
                    _id: start.idNotification,
                    title: this.translate.instant('advertise.text179'),
                    description: this.translate.instant(start.message),
                    created_at: Date.now(),
                    type_notification: start.typeNotification,
                    id_subject: data.id_trans,
                    status: false,
                };
                this.toast.show({ id: start.idNotification, color: 'toast-info', title: this.translate.instant('advertise.text179'), description: this.translate.instant(start.message), link: '/public/transaction/' + data.id_trans });
                setnotification.status = true;
                this.globals.notificationContent.unshift(setnotification);
            }
        });
        this.subscriptionEmitter4 = this.eventEmitterService.updateDataCoin.subscribe(() => {
            const price: string = this.globals.price.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            this.cdr.detectChanges();
            this.statusPercentageBTC = (this.globals.percentage >= 0) ? true : false;
            this.priceBTC.nativeElement.textContent = `${price}`;
            this.percentageBTC.nativeElement.textContent = `${this.globals.percentage}%`;
        });
        this.subscriptionEmitter5 = this.eventEmitterService.openModal.subscribe((data: number) => {
            this.remember = localStorage.getItem('skipData');
            if (data === 3 && this.remember === 'true') {
                //console.log(`Remember: ${this.remember}`);
                this.openModal();
            } else if (data === 1) {
                this.openModalLogin();
            } else {
                this.openModalRegister();
            }
        });
        this.statusPercentageBTC = (this.globals.percentage >= 0) ? true : false;
    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
        if (this.innerWidth < 990) {
            this.isCollapsed = false;
        } else {
            this.isCollapsed = true;
        }
    }
    checkValue(event: any) {
        this.remember = 'false';
        if (event.currentTarget.checked) {
            this.remember = 'true';
        }
        localStorage.setItem('skipData', this.remember);
    }
    ngAfterViewInit() {
    }
    resetNotification() {
        this.globals.notification = 0;
        this.globals.notificationContent.map((e: any) => {
            if (!e.status) {
                this.globals.notification++;
                this.sessionLog.blink(`${this.translate.instant('advertise.text267')}`);
                this.playSound();
            }
        });
        this.cdr.detectChanges();
    }
    resetSwalNotification() {
        this.globals.notification = 0;
        this.globals.notificationContent.map((e: any) => {
            if (!e.status) {
                this.globals.notification++;
                this.sessionLog.blink(`${this.translate.instant('advertise.text267')}`);
            }
        });
        this.cdr.detectChanges();
    }
    getNotification() {
        this.connectApi.getNotification().subscribe({
            next: (res: any) => {
                if (res.content !== null) {
                    this.globals.notification = 0;
                    res.content.map((e: any) => {
                        if (!e.status) {
                            this.globals.notification++;
                        }
                    });
                    this.globals.notificationContent = res.content;
                    this.globals.notificationContent.forEach((item, index, arr) => {
                        console.log(item.title, arr[index].type_notification);
                        arr[index].title = this.translate.instant(item.title);
                        if (arr[index].type_notification === 1) { // notificacion de detransferencia recibida
                            arr[index].description = this.translate.instant(item.description.split('*')[0], { amount: item.description.split('*')[1], crypto: item.description.split('*')[2], wallet: item.description.split('*')[3] });
                        } else if (arr[index].type_notification === 2) { // notificacion de nueva transaccion
                            arr[index].description = this.translate.instant(item.description.split('*')[0], { username: item.description.split('*')[1], publication: item.description.split('*')[2] });
                        } else if (arr[index].type_notification === 5) { // notificacion de mensaje nuevo soporte
                            arr[index].description = item.description.replace('advertise.text168', this.translate.instant('advertise.text168'));
                        } else if (arr[index].type_notification === 7) { // notifica de nuevo mensaje de transaccion
                            arr[index].title = this.translate.instant('advertise.text245');
                        } else if (arr[index].type_notification === 8) { // notificacion de publicacion deshabilitada
                            if (typeof arr[index].description.split('*')[1] !== "undefined") {
                                arr[index].description = this.translate.instant(item.description.split('*')[0], { id: item.description.split('*')[1] });
                            } else {
                                arr[index].description = this.translate.instant(item.description, { id: item.id_subject });
                            }
                        } else if (arr[index].type_notification === 9) { // notificacion de pago realizado
                            arr[index].title = this.translate.instant('PaymentMade');
                            arr[index].description = this.translate.instant('advertise.text168');
                        } else if (arr[index].description) {
                            arr[index].description = this.translate.instant(item.description, { id: item.id_subject });
                        }
                    });
                    this.cdr.detectChanges();
                }
            }, error: (err) => { console.log('Error en obtener las notificaciones: '+ err) }
        });
    }
    showChatSupport() {
        this.globals.showChatSupport = true;
        this.cdr.detectChanges();
    }
    readAll() {
        this.connectApi.viewAllNotification().subscribe({
            next: (res: any) => {
                this.globals.notification = 0;
                this.globals.notificationContent.map((e: any) => {
                    e.status = true;
                    this.cdr.detectChanges();
                });
            }, error: () => { console.log('erorrr') }
        });
    }
    trackByFn(index, item) {
        return index; // unique id corresponding to the item
    }
    logout() {
        this.sessionLog.logout();
        this.cdr.detectChanges();
    }
    move(oldIndex, newIndex) {
        while (oldIndex < 0) {
            oldIndex += this.globals.notificationContent.length;
        }
        while (newIndex < 0) {
            newIndex += this.globals.notificationContent.length;
        }
        if (newIndex >= this.globals.notificationContent.length) {
            let k = newIndex - this.globals.notificationContent.length;
            while ((k--) + 1) {
                this.globals.notificationContent.push(undefined);
            }
        }
        this.globals.notificationContent.splice(newIndex, 0, this.globals.notificationContent.splice(oldIndex, 1)[0]);
    }
    ngOnDestroy() {
        this.toast.clear();
        if (this.subscriptionEmitter1) { this.subscriptionEmitter1.unsubscribe(); }
        if (this.subscriptionEmitter2) { this.subscriptionEmitter2.unsubscribe(); }
        if (this.subscriptionEmitter3) { this.subscriptionEmitter3.unsubscribe(); }
        if (this.subscriptionEmitter4) { this.subscriptionEmitter4.unsubscribe(); }
        if (this.subscriptionEmitter5) { this.subscriptionEmitter5.unsubscribe(); }
        if (this.subscriptionEmitter) { this.subscriptionEmitter.unsubscribe(); }
    }
    onSubmitUpdate() {
        // stop here if form is invalid
        if (this.updateForm.invalid) {
            this.cdr.detectChanges();
            return;
        }
        this.connectApi.sendEmailCode({
            email: this.globals.dataUser.email.address,
            username: this.globals.dataUser.username
        }).subscribe({
            next: (res) => {
                Swal.fire({
                    html: '<h3>' + this.translate.instant('body.text47') + '</h3>',
                    input: 'text',
                    inputPlaceholder: this.translate.instant('body.text184'),
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: false,
                    confirmButtonText: this.translate.instant('authentication.text32'),
                }).then((result) => {
                    if (result.value) {
                        const dataSend: any = this.updateForm.value;
                        dataSend.code = parseInt(result.value, 10);
                        dataSend.address = this.globals.dataUser.address;
                        dataSend.phone = {
                            code: dataSend.phone_code,
                            number: dataSend.phone_number,
                            verify: 0,
                            updated_at: this.globals.dataUser.phone.updated_at
                        };
                        delete dataSend.phone_code;
                        delete dataSend.phone_number;
                        dataSend.email = {
                            address: this.globals.dataUser.email.address,
                            updated_at: this.globals.dataUser.email.updated_at,
                            verify: this.globals.dataUser.email.verify,
                        };
                        let dataTwofactor = {
                            status: false
                        };
                        if (this.globals.dataUser?.twofactor?.status) {
                            dataTwofactor.status = this.globals.dataUser.twofactor.status
                        }
                        dataSend.twofactor = dataTwofactor;
                        this.connectApi.updateUserData(dataSend).subscribe({
                            next: (resp: any) => {
                                localStorage.setItem('dataUser', JSON.stringify(resp.content));
                                this.globals.dataUser = JSON.parse(localStorage.getItem('dataUser'));
                                Swal.fire({
                                    icon: 'success',
                                    title: this.translate.instant('body.text53'),
                                    text: resp.content.message
                                });
                                this.modalService.dismissAll();
                            }, error: () => { console.log('erorrr') }
                        });
                    }
                });
            }, error: () => { console.log('erorrr') }
        });
        this.cdr.detectChanges();
    }
    changeLang(lang: string) {
        localStorage.setItem('lang', lang);
        this.globals.activeLang = lang;
        this.translate.use(lang);
        this.globals.textLang =
            this.globals.languages[this.globals.languages.map((e: any) => e.key).indexOf(localStorage.getItem('lang'))].title;
        if (this.globals.dataUser && this.globals.dataUser._id) {
            this.globals.dataUser.config.lang = lang;
            this.connectApi.updateConfigUser(this.globals.dataUser.config).subscribe({ error: () => { console.log('error al cambiar de lenguaje') } });
        }
        this.cdr.detectChanges();
        this.getNotification();
    }
    playSound() {
        const audio = new Audio('assets/sounds/notification.mp3');
        audio.play();
    }
    setActiveNotification(notification: any) {
        this.globals.activeNotification = notification._id;
    }
    openModalLogin() {
        this.modalService.open(LoginComponent);
    }
    rightSideToggle() {
        if (!this.globals.showChatSupport) {
            this.connectApi.getChat().subscribe({
                next: (res: any) => {
                    if (res.content != null) {
                        if (res.content.participants[1]) {
                            this.globals.receiverChatSupport = res.content.participants[1];
                        }
                        this.globals.idChatSupport = res.content._id;
                        this.connectApi.getChatCompletedSupport(this.globals.idChatSupport).subscribe((res2: any) => {
                            this.globals.chatSupport = res2.content.messages;
                        });
                    }
                }, error: () => { console.log('erorrr') }
            });
        }
        this.globals.showChatSupport = !this.globals.showChatSupport;
        this.cdr.detectChanges();
    }
}
