import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Globals } from '../../shared/globals';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { faClose } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-chat-window',
    templateUrl: './chat-window.component.html',
    styleUrls: ['./chat-window.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatWindowComponent implements OnInit {
    icons = { faClose };
    @ViewChild('scrollMe') private myScrollContainer: ElementRef;
    @Input() dataContentChat: any[];
    @Input() idChat: any;
    @Input() dataScreenHeight: any;
    @Input() statusTransaction: any;
    @Input() applicant: any[];
    @Input() responsable: any[];
    @Output() setMessage = new EventEmitter<object>();
    file = false;
    fileToUpload: any;
    chatForm: UntypedFormGroup;
    imgURL: any;
    nameFiel = '';
    imgServer = environment.imgServer;
    constructor(
        public globals: Globals,
        private formBuilder: UntypedFormBuilder,
        private cd: ChangeDetectorRef,
        public translate: TranslateService,
    ) {
    }

    ngOnInit() {
        this.chatForm = this.formBuilder.group({
            text: ['', [Validators.maxLength(5000)]]
        });
    }
    update() {
        this.scrollToBottom();
        this.cd.detectChanges();
    }
    sendMessage() {
        if (this.chatForm.value.text.trim().length === 0 && !this.file) {
            return;
        }
        this.setMessage.emit({ msg: this.chatForm.value.text.trim(), file: this.file, fileToUpload: this.fileToUpload });
        this.chatForm.reset();
        this.chatForm.controls.text.setValue('');
        if (this.file) {
            this.dataScreenHeight = this.dataScreenHeight + 80;
        }
        this.file = false;
        this.fileToUpload = null;
        this.cd.detectChanges();
    }
    onSelectFile(event: any) {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = () => { // called once readAsDataURL is completed
                this.file = true;
                this.imgURL = reader.result;
                this.fileToUpload = event.target.files[0];
                this.nameFiel = event.target.files[0].name;
                this.dataScreenHeight = (this.dataScreenHeight - 80);
                this.cd.detectChanges();
            };
        }
    }
    openFile(img) {
        Swal.fire({
            imageUrl: environment.imgServer + '/uploads/chat-' + this.idChat + '/' + img,
            imageAlt: 'LocalCoiners',
        });
    }
    trackByFn(index, item) {
        return index; // unique id corresponding to the item
    }
    removeFile() {
        this.dataScreenHeight = this.dataScreenHeight + 80;
        this.file = false;
        this.fileToUpload = null;
        this.cd.detectChanges();
    }
    scrollToBottom(): void {
        if (this.myScrollContainer.nativeElement.scrollHeight - this.dataScreenHeight >= this.myScrollContainer.nativeElement.scrollTop) {
            setTimeout(() => {
                this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
            }, 100);
        }
    }
}
