import { Injectable } from '@angular/core';
import { Globals } from '../shared/globals';
@Injectable({
	providedIn: 'root'
})
export class ToastService {
	constructor(public globals: Globals,) { }

	show(options: any = {}) {
		const isFound = this.globals.toasts.some(element => {
			if (element.id === options.id) {
				return true;
			}
			return false;
		});
		if (!isFound) {
			this.globals.toasts.push(options);
			localStorage.setItem('toasts', JSON.stringify(this.globals.toasts));
		}
	}

	remove(toast) {
		this.globals.toasts = this.globals.toasts.filter(t => t !== toast);
		localStorage.setItem('toasts', JSON.stringify(this.globals.toasts));
	}

	clear() {
		this.globals.toasts.splice(0, this.globals.toasts.length);
		localStorage.setItem('toasts', JSON.stringify(this.globals.toasts));
	}
}
