import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EventEmitterService {
    sessionUsers = new EventEmitter();
    logout = new EventEmitter();
    reloadCaptcha = new EventEmitter();
    successConnectSocket = new EventEmitter();
    sendMessageTransaction = new EventEmitter();
    finishTransaction = new EventEmitter();
    updateAmountWallet = new EventEmitter();
    updateStatus = new EventEmitter();
    addNotification = new EventEmitter();
    updateDataCoin = new EventEmitter();
    updateTimeTransaction = new EventEmitter();
    updateTimeStartDispute = new EventEmitter();
    updateChatSupport = new EventEmitter();
    updateTokenCompleted = new EventEmitter();
    isLoading = new EventEmitter();
    notifiPayment = new EventEmitter();
    openModal = new EventEmitter();
    updateStatusTransaction = new EventEmitter();
    responseAdminVerify = new EventEmitter();
    updateListSells = new EventEmitter();
    updateDataGlobal = new EventEmitter();
}
