<div ngbDropdown class="navbar-0">
    <div class="container-fluid" style="background-color: white;" *ngIf="innerWidth < 990">
        <div class="row">
            <div class="col-8">
                <a [routerLink]="'/'">
                    <img src="assets/images/icon/Grupo_1623.svg" alt="homepage" class="img-fluid" width="250" />
                </a>
            </div>
            <div class="col-4 text-end pt-2">
                <button class="btn btn-outline-dark mt-2" type="button" (click)="isCollapsed = !isCollapsed">
                    <fa-icon class="fw-bolder" [icon]="icons.faBars"></fa-icon>
                </button>
            </div>
            <div class="col-12 pt-3" *ngIf="globals.dataUser != undefined && globals.dataUser._id">
                <div class="parent">
                    <div class="child">
                        <div ngbDropdown class="mr-items mt-1 dropdown">
                            <a class="text-dark" href="javascript:void(0);" id="dropdownNoti" ngbDropdownToggle
                                title="{{'nav.text15' | translate}}">
                                <fa-icon [icon]="icons.faBell" class="icon-social"
                                    [ngClass]="globals.notification > 0 ? 'faa-ring animated' : '' "></fa-icon>
                            </a>
                            <div class="notify" *ngIf="globals.notification > 0">
                                <span class="heartbit"></span>
                                <span class="point"></span>
                                <b style="color: white;">{{globals.notification}}</b>
                            </div>
                            <div ngbDropdownMenu aria-labelledby="dropdownNoti" class="dropdown-menu p-3 shadow-lg"
                                style="width: 400px;">
                                <h4 class="text-center drop-title">{{'nav.text16' | translate}}</h4>
                                <button *ngIf="globals.notificationContent.length > 0"
                                    class="btn btn-sm btn-outline-secondary ml-2" (click)="readAll()"
                                    type="button">{{'nav.text22' | translate}}</button>
                                <div class="list-group list-group-flush" id="list-tab" role="tablist"
                                    *ngIf="globals.notificationContent.length > 0">
                                    <div *ngFor="let notification of globals.notificationContent; trackBy: trackByFn;"
                                        class="dropdown-item">
                                        <a *ngIf="notification.type_notification == 5" (click)="showChatSupport()"
                                            class="list-group-item list-group-item-action" data-toggle="list"
                                            [ngClass]="(!notification.status) ? 'noView' : 'ciew'">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-2 bell-align">
                                                        <i class="fa fa-bell bell-shape"></i>
                                                    </div>
                                                    <div class="col-10">
                                                        <div class="col-12 notif-title">
                                                            <div class="row">
                                                                <div class="col-9">
                                                                    <h5
                                                                        [ngClass]="(!notification.status) ? 'noView' : 'view'">
                                                                        {{'body.text216' | translate}}</h5>
                                                                </div>
                                                                <div class="col-3">
                                                                    <span class="label label-warning"
                                                                        *ngIf="!notification.status">{{'nav.text23' |
                                                                        translate}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <small class="breaks"
                                                            *ngIf="notification.description">{{(notification.description.length>100)?
                                                            (notification.description |
                                                            slice:0:100)+'..':(notification.description)}}</small>
                                                        <br>
                                                        <small class="text-muted">
                                                            {{notification.created_at | date: 'MMM d, y, h:mm:ss a'}}
                                                            {{notification.type_notification}}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        <a *ngIf="notification.type_notification != 5"
                                            [routerLink]="(notification.type_notification == 1) ? ['/settings/balance'] : (notification.type_notification == 8) ? ['/public/preview', notification.id_subject] : (notification.type_notification == 4 || notification.type_notification == 6  || notification.type_notification == 7 || notification.type_notification == 10 || notification.type_notification != 5) ? ['/public/transaction', notification.id_subject] : ['']"
                                            class="list-group-item list-group-item-action" data-toggle="list"
                                            (click)="setActiveNotification(notification)"
                                            [ngClass]="(!notification.status) ? 'noView' : 'view'"
                                            [class.active]="notification.id == globals.activeNotification"
                                            style="padding-left:0px !important; padding-right: 0px !important;">
                                            <div class="row">
                                                <div class="col-2 bell-align">
                                                    <fa-icon [icon]="icons.faBell"></fa-icon>
                                                </div>
                                                <div class="col-10">
                                                    <div class="col-12 notif-title">
                                                        <div class="row">
                                                            <div class="col-9">
                                                                <h5 [ngClass]="(!notification.status) ? 'noView' : ''">
                                                                    {{notification.title}}</h5>
                                                            </div>
                                                            <div class="col-3">
                                                                <span class="label label-warning"
                                                                    *ngIf="!notification.status">{{'nav.text23' |
                                                                    translate}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <small class="breaks" *ngIf="notification.description"
                                                        style="width:100% !important;">{{(notification.description.length>100)?
                                                        (notification.description |
                                                        slice:0:100)+'..':(notification.description)}}</small>
                                                    <br>
                                                    <small class="text-muted">
                                                        {{notification.created_at | date: 'MMM d, y, h:mm:ss a'}}
                                                    </small>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div *ngIf="globals.notificationContent.length == 0" class="text-center text-muted">
                                    <br>
                                    <i class="fa fa-close fa-4x"></i>
                                    <p>{{'nav.text17' | translate}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="child" [class.active]="globals.showMenu == 6"
                        [class.nav-border-bottom]="globals.showMenu == 6">
                        <a [routerLink]="'/settings/balance'" (click)="addExpandClass(6)">
                            <svg id="Group_1612" data-name="Group 1612" xmlns="http://www.w3.org/2000/svg" width="18"
                                viewBox="0 0 24.193 24.193" style="margin-top: -3px;">
                                <g id="billetera" transform="translate(0 0)">
                                    <g id="Group_1480" data-name="Group 1480" transform="translate(0 0)">
                                        <path id="Path_7505" [class.svg-active]="globals.showMenu == 6"
                                            data-name="Path 7505"
                                            d="M19.137,12.144a2.835,2.835,0,0,0,0,5.67h4.016l.047,0,.047,0a.945.945,0,0,0,.945-.945V9.451a3.777,3.777,0,0,0-1.567-3.061L19.411,1.115A.945.945,0,0,0,18.106.8l-2.371,1.47L14.666.465a.945.945,0,0,0-1.3-.327L4.258,5.67H2.835a.946.946,0,0,1-.945-.921.946.946,0,0,1,.945-.921H4.583a.945.945,0,0,0,0-1.89H2.835A2.84,2.84,0,0,0,.077,4.116.941.941,0,0,0,0,4.489V20.413a3.784,3.784,0,0,0,3.78,3.78H20.412A3.775,3.775,0,0,0,24,21.61a.945.945,0,1,0-1.793-.6A1.888,1.888,0,0,1,20.412,22.3H3.78a1.892,1.892,0,0,1-1.89-1.89V7.4a2.822,2.822,0,0,0,.945.162H20.412a1.892,1.892,0,0,1,1.89,1.89v2.693Zm3.166,3.78H19.137a.945.945,0,0,1,0-1.89H22.3ZM7.9,5.67,13.525,2.25l1.006,1.7a.947.947,0,0,0,.069.137.958.958,0,0,0,.067.094l.879,1.487Zm9.845,0L16.7,3.9l1.6-.99L19.974,5.67Z"
                                            transform="translate(0 0)" fill="#ffffff" />
                                    </g>
                                </g>
                                <path id="Path_7503" [class.svg-active]="globals.showMenu == 6" data-name="Path 7503"
                                    d="M154.611,109.912a1.131,1.131,0,0,0,.561-1.169c-.1-.724-.958-1-1.639-1.054V106.6h-.671v1.078h-.45V106.6h-.671v1.078H150.39v.695h.5c.225,0,.335.067.335.249v2.985c0,.249-.125.326-.259.326h-.513v.709h1.3v1.1h.676v-1.1h.45v1.1h.656v-1.1h.144c1.38,0,1.84-.676,1.84-1.6A1.193,1.193,0,0,0,154.611,109.912Zm-2.2-1.495h.479c.335,0,1,.057,1,.6a.676.676,0,0,1-.589.714h-.886Zm.743,3.45v0h-.743v-1.437h.863c.2,0,.958.067.958.6S153.874,111.867,153.155,111.867Z"
                                    transform="translate(-141.593 -95.413)" fill="#ffffff" />
                            </svg>
                            <span style="font-weight: 800;">{{
                                globals.dataUser.amount_wallet
                                ?
                                (globals.dataUser.amount_wallet | number:'1.8-8') : '0.00000000'}}</span>
                        </a>
                    </div>
                    <div class="child" ngbDropdown #dropUser="ngbDropdown"
                        (mouseenter)="$event.stopPropagation(); dropUser.open();">
                        <a class="separator" (click)="addExpandClass(7)" aria-expanded="false"
                            id="dropdownBasicUserMenu" ngbDropdownToggle>
                            <div class="avatar-img-config"
                                [ngStyle]="{'background-image': 'url(\''+globals.Thumbnail(globals.dataUser.image)+'\')'}">
                            </div>
                        </a>
                        <ul ngbDropdownMenu aria-labelledby="dropdownBasicUserMenu" class="custom-dropdown text-center">
                            <li style="font-size:23px;font-weight: 800;">
                                {{globals.dataUser.username}}</li>
                            <li style="font-size:15px;font-weight: 400;">
                                {{globals.dataUser.email.address}}</li>
                            <li>&nbsp;</li>
                            <li>
                                <button (click)="dropUser.close();"
                                    [routerLink]="['/public/previewuser', globals.dataUser.username]"> {{'sidebar.text6'
                                    |
                                    translate}}</button>
                            </li>
                            <li class="mt-3">
                                <a class="subbar" style="font-size: 9pt;" (click)="dropUser.close();"
                                    [routerLink]="'/settings/'">
                                    <fa-icon [icon]="icons.faUser"></fa-icon>&nbsp;&nbsp;{{'nav.text10' |
                                    translate}}</a>
                            </li>
                            <li class="mt-3">
                                <a class="subbar" style="font-size: 9pt;" (click)="dropUser.close();"
                                    [routerLink]="'/trust'">
                                    <fa-icon [icon]="icons.faStar"></fa-icon>&nbsp;&nbsp;{{'nav.text13' |
                                    translate}}</a>
                            </li>
                            <li class="mt-3 mb-3">
                                <a class="subbar" style="font-size: 9pt;" href="javascript:void(0);" (click)="logout()">
                                    <fa-icon [icon]="icons.faPowerOff"></fa-icon>&nbsp;&nbsp;{{'nav.text14' |
                                    translate}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isCollapsed" style="background-color: white;" class="navbar-1">
        <div class="container-fluid pt-1 pb-2">
            <div class="row">
                <div class="col-lg-3" *ngIf="innerWidth > 990">
                    <a class="navbar-brand" [routerLink]="'/'">
                        <img src="assets/images/icon/Grupo_1623.svg" alt="homepage" class="img-fluid" width="250" />
                    </a>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12 col-xs-12 text-muted" style="padding-top: 15px;">
                    <div class="btc-price">
                        <span>BTC/USD </span>
                        <span class="bg-white bar-porcentaje" #priceBTC> {{globals.price | number: '1.2-2'}}</span>
                        <span class="bg-white bar-porcentaje">
                            <fa-icon [icon]="!statusPercentageBTC ? icons.faArrowDown : icons.faArrowUp"
                                [ngClass]="!statusPercentageBTC ? 'text-danger' : 'text-success'"></fa-icon>&nbsp;
                            <span #percentageBTC>{{globals.percentage}}%</span>
                        </span>
                        <button type="button" class="btn btn-primary"
                            style="background-color:#F8A643;border-color:#F8A643;height:37px; border-radius: 0px !important;position: absolute;right: 0px;top: 0px;"
                            [routerLink]="'/'">{{'nav_btc_info.text1' | translate}}</button>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-3"
                    [ngClass]="innerWidth > 990 ? 'text-md-end ' : 'text-center'" *ngIf="innerWidth > 990">
                    <a href="javascript:void(0)" class="icon-social bar">
                        <fa-icon [icon]="icons.faFacebookF"></fa-icon>
                    </a>
                    <a href="javascript:void(0)" class="icon-social bar">
                        <fa-icon [icon]="icons.faInstagram"></fa-icon>
                    </a>
                    <a href="javascript:void(0)" class="icon-social bar">
                        <fa-icon [icon]="icons.faTwitter"></fa-icon>
                    </a>
                    <a href="javascript:void(0)" class="linkedin bar">
                        <fa-icon [icon]="icons.faLinkedinIn"></fa-icon>
                    </a>
                    <a *ngIf="globals.dataUser != undefined && globals.dataUser._id" [routerLink]="'/dashboard'"
                        title="{{'nav.text12' | translate}}" class="mr-items">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 36.789 29.245" class="d-align">
                            <path class="a"
                                d="M31.267,58.021a1.437,1.437,0,0,0-1.826-.171c-2.15,1.466-12.936,8.836-14.1,9.995a4.311,4.311,0,0,0,6.1,6.1C22.6,72.783,29.972,62,31.438,59.847A1.438,1.438,0,0,0,31.267,58.021ZM19.411,71.91a1.437,1.437,0,0,1-2.033-2.032c.45-.445,3.513-2.629,7.28-5.248C22.04,68.4,19.856,71.46,19.411,71.91Zm17.378-1.016a18.322,18.322,0,0,1-3.117,10.212,1.437,1.437,0,0,1-1.813.5l-3.018-1.437a1.437,1.437,0,0,1,1.236-2.6l1.852.882a15.47,15.47,0,0,0,.618-13.939,1.437,1.437,0,1,1,2.62-1.183A18.253,18.253,0,0,1,36.789,70.894ZM8.549,78.239a1.437,1.437,0,0,1-.662,1.922L4.941,81.6a1.437,1.437,0,0,1-1.825-.493A18.389,18.389,0,0,1,25.956,54.12a1.437,1.437,0,1,1-1.182,2.62,15.342,15.342,0,0,0-4.941-1.3V57.6a1.437,1.437,0,0,1-2.874,0V55.439A15.54,15.54,0,0,0,5.153,62.8l2.007.963a1.437,1.437,0,1,1-1.243,2.591l-2.033-.975a15.448,15.448,0,0,0,.969,13.06l1.774-.865A1.437,1.437,0,0,1,8.549,78.239Z"
                                transform="translate(0 -52.499)" />
                        </svg>
                    </a>
                    <b class="text-danger mr-items"
                        *ngIf="globals.dataUser != undefined && globals.dataUser._id && globals.dataUser.status == 2">
                        <fa-icon [icon]="icons.faAddressCard"></fa-icon>
                        {{'nav.text9' | translate}}
                    </b>
                    <div ngbDropdown class="mr-items mt-1 dropdown text-start" style="display: inline-block;">
                        <a class="text-dark" href="javascript:void(0);" id="dropdownNoti" ngbDropdownToggle
                            title="{{'nav.text15' | translate}}">
                            <fa-icon [icon]="icons.faBell"></fa-icon>
                        </a>
                        <div class="notify" *ngIf="globals.notification > 0">
                            <span class="heartbit"></span>
                            <span class="point"></span>
                            <b style="color: white;">{{globals.notification}}</b>
                        </div>
                        <div ngbDropdownMenu aria-labelledby="dropdownNoti" class="dropdown-layout">
                            <!--<h4 class="text-center drop-title">{{'nav.text16' | translate}}</h4>-->
                            <button style="margin-left: 15px;" *ngIf="globals.notificationContent.length > 0"
                                class="btn btn-sm btn-outline-warning" (click)="readAll()" type="button">{{'nav.text22'
                                | translate}}</button>
                            <div class="list-group list-group-flush" id="list-tab" role="tablist"
                                *ngIf="globals.notificationContent.length > 0"
                                style="max-height: 500px; overflow-y: auto;">
                                <div *ngFor="let notification of globals.notificationContent; trackBy: trackByFn;">
                                    <a *ngIf="notification.type_notification == 5" (click)="showChatSupport()"
                                        class="list-group-item list-group-item-action" data-toggle="list"
                                        [ngClass]="(!notification.status) ? 'noView' : 'ciew'">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="col-12 notif-title">
                                                        <div class="row">
                                                            <div class="col-9">
                                                                <h5
                                                                    [ngClass]="(!notification.status) ? 'noView' : 'view'">
                                                                    {{'body.text216' | translate}}</h5>
                                                            </div>
                                                            <div class="col-3">
                                                                <span class="label label-warning"
                                                                    *ngIf="!notification.status">{{'nav.text23' |
                                                                    translate}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <small class="breaks"
                                                        *ngIf="notification.description">{{(notification.description.length>100)?
                                                        (notification.description |
                                                        slice:0:100)+'..':(notification.description)}}</small>
                                                    <br>
                                                    <small class="text-muted">
                                                        {{notification.created_at | date: 'MMM d, y, h:mm:ss a'}}
                                                        {{notification.type_notification}}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <div class="card notification-card" (click)="setActiveNotification(notification)"
                                        [ngClass]="(!notification.status) ? 'noView' : 'view'"
                                        [class.active]="notification.id == globals.activeNotification">
                                        <div class="card-body p-3">
                                            <div class="row">
                                                <!-- Contenido de la notificación -->
                                                <div class="col-2 bell-icon-custom">
                                                    <fa-icon [icon]="icons.faBell"></fa-icon>
                                                </div>
                                                <div class="col-10">
                                                    <div class="d-flex justify-content-between align-items-start">
                                                        <!-- Título -->
                                                        <h5 class="mb-1 text-dark"
                                                            [ngClass]="(!notification.status) ? 'noView' : ''">
                                                            {{notification.title}}
                                                        </h5>
                                                        <!-- Etiqueta de estado -->
                                                        <span class="badge badge-warning text-uppercase"
                                                            *ngIf="!notification.status">
                                                            {{'nav.text23' | translate}}
                                                        </span>
                                                    </div>
                                                    <!-- Descripción -->
                                                    <p class="mb-1 text-muted" *ngIf="notification.description">
                                                        {{(notification.description.length > 100)
                                                        ? (notification.description | slice: 0:100) + '...'
                                                        : notification.description}}
                                                    </p>
                                                    <!-- Fecha -->
                                                    <small class="text-muted">
                                                        {{notification.created_at | date: 'MMM d, y, h:mm:ss a'}}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="globals.notificationContent.length == 0" class="text-center text-muted">
                                <br>
                                <fa-icon [icon]="icons.faClose"></fa-icon>
                                <p>{{'nav.text17' | translate}}</p>
                            </div>
                        </div>
                    </div>
                    <span ngbDropdown class="mr-items mt-1">
                        <a class="navdivnk text-dark" href="javascript:void(0);" id="dropdownBasic1" ngbDropdownToggle
                            [title]="'nav.text19' | translate">
                            <fa-icon [icon]="icons.faEarth" style="font-size:13pt"></fa-icon> {{globals.textLang |
                            translate}}
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                            class="dropdowns-language animated bounceInDown">
                            <h4 class="text-center drop-title">{{'nav.text19' | translate}}</h4>
                            <div class="list-group">
                                <a href="#" (click)="changeLang(language.key)"
                                    class="list-group-item list-group-item-action"
                                    *ngFor="let language of globals.languages; trackBy: trackByFn;">
                                    <h5><fa-icon [icon]="icons.faEarth"></fa-icon> {{language.title | translate}}</h5>
                                </a>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <div class="mt-2 sidebar">
            <div class="container-fluid d-flex justify-content-between">
                <div class="item-menu w-2" *ngIf="innerWidth < 990">
                    <span ngbDropdown class="mr-items mt-1">
                        <a class="navdivnk text-dark" href="javascript:void(0);" id="dropdownBasic1" ngbDropdownToggle
                            [title]="'nav.text19' | translate">
                            <fa-icon [icon]="icons.faEarth" style="font-size:13pt"></fa-icon> {{globals.textLang |
                            translate | uppercase}}
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                            class="dropdowns-language animated bounceInDown">
                            <h4 class="text-center drop-title">{{'nav.text19' | translate}}</h4>
                            <div class="list-group">
                                <a href="#" (click)="changeLang(language.key)"
                                    class="list-group-item list-group-item-action"
                                    *ngFor="let language of globals.languages; trackBy: trackByFn;">
                                    <h5><fa-icon [icon]="icons.faEarth"></fa-icon> {{language.title | translate}}</h5>
                                </a>
                            </div>
                        </div>
                    </span>
                </div>

                <div class="item-menu w-2"
                    *ngIf="innerWidth < 990 && globals.dataUser != undefined && globals.dataUser._id">
                    <a [routerLink]="'/dashboard'" title="{{'nav.text12' | translate}}" class="mr-items">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 36.789 29.245" class="d-align">
                            <path class="a"
                                d="M31.267,58.021a1.437,1.437,0,0,0-1.826-.171c-2.15,1.466-12.936,8.836-14.1,9.995a4.311,4.311,0,0,0,6.1,6.1C22.6,72.783,29.972,62,31.438,59.847A1.438,1.438,0,0,0,31.267,58.021ZM19.411,71.91a1.437,1.437,0,0,1-2.033-2.032c.45-.445,3.513-2.629,7.28-5.248C22.04,68.4,19.856,71.46,19.411,71.91Zm17.378-1.016a18.322,18.322,0,0,1-3.117,10.212,1.437,1.437,0,0,1-1.813.5l-3.018-1.437a1.437,1.437,0,0,1,1.236-2.6l1.852.882a15.47,15.47,0,0,0,.618-13.939,1.437,1.437,0,1,1,2.62-1.183A18.253,18.253,0,0,1,36.789,70.894ZM8.549,78.239a1.437,1.437,0,0,1-.662,1.922L4.941,81.6a1.437,1.437,0,0,1-1.825-.493A18.389,18.389,0,0,1,25.956,54.12a1.437,1.437,0,1,1-1.182,2.62,15.342,15.342,0,0,0-4.941-1.3V57.6a1.437,1.437,0,0,1-2.874,0V55.439A15.54,15.54,0,0,0,5.153,62.8l2.007.963a1.437,1.437,0,1,1-1.243,2.591l-2.033-.975a15.448,15.448,0,0,0,.969,13.06l1.774-.865A1.437,1.437,0,0,1,8.549,78.239Z"
                                transform="translate(0 -52.499)" />
                        </svg> {{'nav.text12' | translate | uppercase}}
                    </a>
                </div>

                <div class="item-menu w-2" [ngClass]="innerWidth > 990 ? 'float-left nav-border-motion' : ''"
                    [class.active]="globals.showMenu == 1" [class.nav-border-bottom]="globals.showMenu == 1">
                    <a [routerLink]="'/'" (click)="addExpandClass(1)" class="separator">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 33 33"
                            style="margin-top: -3px;">
                            <g id="Group_1603" data-name="Group 1603" transform="translate(-597 -108)">
                                <path id="Path_6599" [class.svg-active]="globals.showMenu == 1" data-name="Path 6599"
                                    d="M110.124,156.476l-1.275-4.276h6.1a1.291,1.291,0,0,0,0-2.572h-6.866l-1.178-3.951a1.186,1.186,0,0,0-1.114-.887l-4.4,0a1.291,1.291,0,0,0,0,2.571h3.547l6.305,21.155a4.169,4.169,0,0,0-2.159,1.276,5.007,5.007,0,0,0,0,6.625,4.017,4.017,0,0,0,6.048,0,4.9,4.9,0,0,0,1.252-3.313,5.032,5.032,0,0,0-.459-2.114h8.147a5.016,5.016,0,0,0-.461,2.114,4.9,4.9,0,0,0,1.253,3.313,4.017,4.017,0,0,0,6.047,0,5,5,0,0,0,0-6.619,4.136,4.136,0,0,0-3.027-1.378H113.683l-.676-2.268,14.885,0a1.187,1.187,0,0,0,1.114-.887l4.134-13.87a1.388,1.388,0,0,0,.084-.478,1.233,1.233,0,0,0-1.173-1.286h-7.714a1.291,1.291,0,0,0,0,2.572h6.1l-1.275,4.276Zm18.27,2.57h-17.5l1.351,4.533h14.8Zm-8.752-9.417a1.291,1.291,0,1,0,1.174,1.286A1.233,1.233,0,0,0,119.642,149.629Zm8.33,21.358a1.846,1.846,0,0,1,1.285.618,2.266,2.266,0,0,1,0,2.994,1.813,1.813,0,0,1-2.728,0,2.266,2.266,0,0,1,0-2.994,1.855,1.855,0,0,1,1.231-.615A1.274,1.274,0,0,1,127.972,170.987Zm-14.495.622a2.261,2.261,0,0,1,0,2.99,1.813,1.813,0,0,1-2.729,0,2.259,2.259,0,0,1,0-2.99,1.812,1.812,0,0,1,2.729,0Z"
                                    transform="translate(496.777 -36.789)" fill="#ffffff" />
                            </g>
                        </svg>
                        <span>&nbsp;&nbsp;{{'breadcrumbs.text2' | translate | uppercase}}</span>
                    </a>
                </div>

                <div [ngClass]="innerWidth > 990 ? 'float-left nav-border-motion' : ''" class="item-menu w-2"
                    [class.active]="globals.showMenu == 2">
                    <a [routerLink]="'/sell'" (click)="addExpandClass(2)" class="separator">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 29.01 29"
                            style="margin-top: -3px;">
                            <path class="svg__with-stroke" [class.svg-active]="globals.showMenu == 2"
                                d="M6.64,3.7H13a6.69,6.69,0,0,1,4.22,1.75l13,13a2.94,2.94,0,0,1,0,4.16l-7.57,7.57a2.94,2.94,0,0,1-4.16,0l-13-13A6.69,6.69,0,0,1,3.7,13V6.64A2.94,2.94,0,0,1,6.64,3.7ZM8.17,9.35a.81.81,0,0,0,.57.23,1.31,1.31,0,0,0,.56-.23,1.45,1.45,0,0,0,.24-.57l-.06-.3L9.3,8.22A1.31,1.31,0,0,0,8.74,8H8.59L8.5,8l-.33.18a.8.8,0,0,0,0,1.13Z"
                                transform="translate(-2.85 -2.85)"
                                style="fill:none !important;stroke:#ffffff;stroke-width:1.7000000476837158px" />
                        </svg>
                        <span>&nbsp;&nbsp;{{'breadcrumbs.text4' | translate |
                            uppercase}}</span>
                    </a>
                </div>

                <div *ngIf="globals.dataUser != undefined && globals.dataUser._id"
                    [ngClass]="innerWidth > 990 ? 'float-left nav-border-motion' : ''" class="item-menu w-3-3"
                    [class.active]="globals.showMenu == 4">
                    <a [routerLink]="'/settings/balance'" class="separator">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 21 21"
                            style="margin-top: -3px;">
                            <g id="informacion" transform="translate(5.178 1)">
                                <g id="bitcoin" transform="translate(-5.467)">
                                    <g id="Group_1473" data-name="Group 1473" transform="translate(6.012 4.261)">
                                        <g id="Group_1472" data-name="Group 1472" transform="translate(1 -1)">
                                            <path id="Path_7503" [class.svg-active]="globals.showMenu == 4"
                                                data-name="Path 7503"
                                                d="M157.368,112.074a1.87,1.87,0,0,0,.927-1.933c-.158-1.2-1.584-1.648-2.709-1.743v-1.8h-1.109v1.782h-.745V106.6h-1.109v1.782H150.39v1.149h.832c.372,0,.554.111.554.412v4.935c0,.412-.206.539-.428.539H150.5v1.172h2.154v1.822h1.117v-1.822h.745v1.822H155.6v-1.822h.238c2.281,0,3.042-1.117,3.042-2.638A1.972,1.972,0,0,0,157.368,112.074Zm-3.636-2.471h.792c.554,0,1.648.1,1.648,1a1.117,1.117,0,0,1-.974,1.18h-1.465Zm1.228,5.7V115.3h-1.228v-2.376h1.426c.333,0,1.584.111,1.584,1S156.148,115.306,154.96,115.306Z"
                                                transform="translate(-150.39 -106.601)" fill="#ffffff" />
                                        </g>
                                    </g>
                                    <path id="Path_7504" [class.svg-active]="globals.showMenu == 4"
                                        data-name="Path 7504"
                                        d="M10.46,0A10.46,10.46,0,1,0,20.92,10.46,10.454,10.454,0,0,0,10.46,0Zm0,19.286a8.826,8.826,0,1,1,8.826-8.826A8.821,8.821,0,0,1,10.46,19.286Z"
                                        transform="translate(0.288 -1)" fill="#ffffff" />
                                </g>
                            </g>
                        </svg>
                        <span>&nbsp;&nbsp;{{'breadcrumbs.text8' | translate |
                            uppercase}}</span>
                    </a>
                </div>

                <div [class.float-left]="innerWidth > 990"
                    [ngClass]="globals.dataUser != undefined && globals.dataUser._id ? innerWidth > 500 ? 'w-3-new' : 'w-3' : 'w-4'"
                    class="item-menu-help" ngbDropdown #dropHelp="ngbDropdown"
                    (mouseenter)="$event.stopPropagation(); dropHelp.open();"
                    (mouseleave)="$event.stopPropagation(); dropHelp.close();" [class.active]="globals.showMenu == 5">
                    <a (click)="addExpandClass(5);" id="dropdownBasicHelp" ngbDropdownToggle href="javascript:void(0);"
                        class="separator">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 33 33"
                            style="margin-top: -3px;">
                            <g id="Group_1606" data-name="Group 1606" transform="translate(-1302.822 -99.822)">
                                <g id="informacion" transform="translate(1302.822 99.822)">
                                    <g id="Group_781" data-name="Group 781">
                                        <g id="Group_780" data-name="Group 780">
                                            <circle id="Ellipse_74" [class.svg-active]="globals.showMenu == 5"
                                                data-name="Ellipse 74" cx="1.594" cy="1.594" r="1.594"
                                                transform="translate(14.906 22.81)" fill="#ffffff" />
                                            <path id="Path_6606" [class.svg-active]="globals.showMenu == 5"
                                                data-name="Path 6606"
                                                d="M16.5,0A16.5,16.5,0,1,0,33,16.5,16.491,16.491,0,0,0,16.5,0Zm0,30.422A13.922,13.922,0,1,1,30.422,16.5,13.914,13.914,0,0,1,16.5,30.422Z"
                                                fill="#ffffff" />
                                            <path id="Path_6607" [class.svg-active]="globals.showMenu == 5"
                                                data-name="Path 6607"
                                                d="M181.1,128.5a5.108,5.108,0,0,0-5.1,5.1,1.276,1.276,0,0,0,2.551,0,2.551,2.551,0,1,1,2.551,2.551,1.275,1.275,0,0,0-1.276,1.276v3.189a1.276,1.276,0,0,0,2.551,0v-2.075A5.1,5.1,0,0,0,181.1,128.5Z"
                                                transform="translate(-164.602 -120.157)" fill="#ffffff" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <span>&nbsp;&nbsp;{{'breadcrumbs.text10' | translate | uppercase}}</span>&nbsp;&nbsp;
                        <i class="fa fa-caret-down pl-3" aria-hidden="true"></i>
                    </a>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasicHelp" class="dropdown-submenu">
                        <p class="list-help"><a [routerLink]="'/help/how-buy'">{{'sidebar.text1' |
                                translate}}</a>
                        </p>
                        <p class="list-help"><a [routerLink]="'/help/faq'">{{'sidebar.text2' |
                                translate}}</a></p>
                        <p class="list-help"><a [routerLink]="'/help/guides'">{{'sidebar.text3' |
                                translate}}</a>
                        </p>
                        <p class="list-help" *ngIf="globals.dataUser != undefined && globals.dataUser._id">
                            <a (click)="rightSideToggle()" href="javascript:void(0)">{{'sidebar.text4' |
                                translate}}</a>
                        </p>
                        <p class="list-help"><a [routerLink]="'/help/fees'">{{'sidebar.text5' |
                                translate}}</a></p>
                    </div>
                </div>

                <div class="item-menu w-2-2 float-left"
                    *ngIf="innerWidth > 990 && globals.dataUser != undefined && globals.dataUser._id"
                    [class.active]="globals.showMenu == 6" [class.nav-border-bottom]="globals.showMenu == 6">
                    <a [routerLink]="'/settings/balance'" (click)="addExpandClass(6)">
                        <svg id="Group_1612" data-name="Group 1612" xmlns="http://www.w3.org/2000/svg" width="18"
                            viewBox="0 0 24.193 24.193" style="margin-top: -3px;">
                            <g id="billetera" transform="translate(0 0)">
                                <g id="Group_1480" data-name="Group 1480" transform="translate(0 0)">
                                    <path id="Path_7505" [class.svg-active]="globals.showMenu == 6"
                                        data-name="Path 7505"
                                        d="M19.137,12.144a2.835,2.835,0,0,0,0,5.67h4.016l.047,0,.047,0a.945.945,0,0,0,.945-.945V9.451a3.777,3.777,0,0,0-1.567-3.061L19.411,1.115A.945.945,0,0,0,18.106.8l-2.371,1.47L14.666.465a.945.945,0,0,0-1.3-.327L4.258,5.67H2.835a.946.946,0,0,1-.945-.921.946.946,0,0,1,.945-.921H4.583a.945.945,0,0,0,0-1.89H2.835A2.84,2.84,0,0,0,.077,4.116.941.941,0,0,0,0,4.489V20.413a3.784,3.784,0,0,0,3.78,3.78H20.412A3.775,3.775,0,0,0,24,21.61a.945.945,0,1,0-1.793-.6A1.888,1.888,0,0,1,20.412,22.3H3.78a1.892,1.892,0,0,1-1.89-1.89V7.4a2.822,2.822,0,0,0,.945.162H20.412a1.892,1.892,0,0,1,1.89,1.89v2.693Zm3.166,3.78H19.137a.945.945,0,0,1,0-1.89H22.3ZM7.9,5.67,13.525,2.25l1.006,1.7a.947.947,0,0,0,.069.137.958.958,0,0,0,.067.094l.879,1.487Zm9.845,0L16.7,3.9l1.6-.99L19.974,5.67Z"
                                        transform="translate(0 0)" fill="#ffffff" />
                                </g>
                            </g>
                            <path id="Path_7503" [class.svg-active]="globals.showMenu == 6" data-name="Path 7503"
                                d="M154.611,109.912a1.131,1.131,0,0,0,.561-1.169c-.1-.724-.958-1-1.639-1.054V106.6h-.671v1.078h-.45V106.6h-.671v1.078H150.39v.695h.5c.225,0,.335.067.335.249v2.985c0,.249-.125.326-.259.326h-.513v.709h1.3v1.1h.676v-1.1h.45v1.1h.656v-1.1h.144c1.38,0,1.84-.676,1.84-1.6A1.193,1.193,0,0,0,154.611,109.912Zm-2.2-1.495h.479c.335,0,1,.057,1,.6a.676.676,0,0,1-.589.714h-.886Zm.743,3.45v0h-.743v-1.437h.863c.2,0,.958.067.958.6S153.874,111.867,153.155,111.867Z"
                                transform="translate(-141.593 -95.413)" fill="#ffffff" />
                        </svg>
                        <span style="font-weight: 800;">&nbsp;&nbsp;{{
                            globals.dataUser.amount_wallet
                            ?
                            (globals.dataUser.amount_wallet | number:'1.8-8') : '0.00000000'}}</span>
                    </a>
                </div>


                <div class="item-menu w-0 float-left d-flex flex-column justify-content-center align-items-center"
                    *ngIf="innerWidth > 990 && globals.dataUser != undefined && globals.dataUser._id" ngbDropdown
                    #dropUser="ngbDropdown" (mouseenter)="$event.stopPropagation(); dropUser.open();">
                    <a (click)="addExpandClass(7)" aria-expanded="false" id="dropdownBasicUserMenu" ngbDropdownToggle>
                        <div class="avatar-img-config"
                            [ngStyle]="{'background-image': 'url(\''+globals.Thumbnail(globals.dataUser.image)+'\')'}">
                        </div>
                    </a>
                    <ul ngbDropdownMenu aria-labelledby="dropdownBasicUserMenu" class="custom-dropdown text-center">
                        <li style="font-size:23px;font-weight: 800;">
                            {{globals.dataUser.username}}</li>
                        <li style="font-size:15px;font-weight: 400;">
                            {{globals.dataUser.email.address}}</li>
                        <li>&nbsp;</li>
                        <li>
                            <a (click)="dropUser.close();"
                                [routerLink]="['/public/previewuser', globals.dataUser.username]"
                                class="subbar"><fa-icon [icon]="icons.faStar"></fa-icon>&nbsp;{{'sidebar.text6' |
                                translate}}</a>
                        </li>
                        <li class="mt-3">
                            <a class="subbar" style="font-size: 9pt;" (click)="dropUser.close();"
                                [routerLink]="'/settings/'">
                                <fa-icon [icon]="icons.faUser"></fa-icon>&nbsp;&nbsp;{{'nav.text10' |
                                translate}}</a>
                        </li>
                        <li class="mt-3">
                            <a class="subbar" style="font-size: 9pt;" (click)="dropUser.close();"
                                [routerLink]="'/trust'">
                                <fa-icon [icon]="icons.faCheckCircle"></fa-icon>&nbsp;&nbsp;{{'nav.text13' |
                                translate}}</a>
                        </li>
                        <li class="mt-3 mb-3">
                            <a class="subbar" style="font-size: 9pt;" href="javascript:void(0);" (click)="logout()">
                                <fa-icon [icon]="icons.faPowerOff"></fa-icon>&nbsp;&nbsp;{{'nav.text14' |
                                translate}}</a>
                        </li>
                    </ul>
                </div>

                <div [ngClass]="innerWidth > 990 ? 'float-left text-end' : ''" class="item-menu w-1"
                    *ngIf="globals.dataUser == undefined || globals.dataUser._id == null">
                    <a href="javascript:void(0);" (click)="openModalLogin()" class="separator-r"
                        *ngIf="globals.dataUser == undefined || globals.dataUser._id == null">
                        <fa-icon *ngIf="innerWidth < 990" class="fs-6" [icon]="icons.faRightToBracket"></fa-icon>
                        {{'nav.text6' | translate | uppercase}}
                    </a>
                </div>

                <div [ngClass]="innerWidth > 990 ? 'float-left text-end' : ''" class="item-menu w-1"
                    style="margin-top: 4px;" *ngIf="globals.dataUser == undefined || globals.dataUser._id == null">
                    <button type="button" [ngClass]="innerWidth > 990 ? 'btn-wrn-mod' : 'btn-blank-mod'"
                        (click)="openModalRegister()"
                        *ngIf="globals.dataUser == undefined || globals.dataUser._id == null">
                        <fa-icon *ngIf="innerWidth < 990" class="fs-6" [icon]="icons.faUser"></fa-icon> {{'nav.text7' |
                        translate | uppercase}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-1">
        <ngb-alert *ngIf="globals.openPosts.length > 0 && globals.showOpenPosts" [dismissible]="false" type="info">
            <fa-icon [icon]="icons.faTriangleExclamation"></fa-icon>
            {{ 'breadcrumbs.text37' | translate }} {{globals.numberOpenPosts}} {{ globals.numberOpenPosts < 2 ?
                ('breadcrumbs.text38' | translate) : ('breadcrumbs.text39' | translate) }} <a href="#"
                (click)="handleClick($event, '/dashboard', 2)">{{ 'advertise.text337' | translate}}</a>
        </ngb-alert>
        <ngb-alert *ngIf="globals.disputePosts.length > 0 && globals.showDisputePosts" [dismissible]="false"
            type="warning">
            <fa-icon [icon]="icons.faTriangleExclamation"></fa-icon>
            {{ 'breadcrumbs.text40' | translate }} {{globals.numberDisputePosts}} {{ globals.numberDisputePosts < 2 ?
                ('breadcrumbs.text41' | translate) : ('breadcrumbs.text42' | translate) }} <a href="#"
                (click)="handleClick($event, '/dashboard', 5)">{{ 'advertise.text337' | translate}}</a>
        </ngb-alert>
    </div>
</div>


<!-- modal de completar información -->
<ng-template #content let-modal *ngIf="globals.dataUser && globals.dataUser._id">
    <div class="modal-header">
        <h4 class="modal-title">{{'authentication.text40' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="updateForm" (ngSubmit)="onSubmitUpdate()" #formUpdateData>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <span>{{'advertise.text288' | translate}}</span>
                        <input type="text" class="form-control form-control-line" formControlName="first_name"
                            [(ngModel)]="globals.dataUser.first_name">
                    </div>
                    <div class="form-group">
                        <span>{{'advertise.text289' | translate}}</span>
                        <input type="text" class="form-control form-control-line" formControlName="last_name"
                            [(ngModel)]="globals.dataUser.last_name">
                    </div>
                    <div class="form-group">
                        <span>{{'body.text4' | translate}}</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                            {{'body.text5' | translate}}</span>
                        <br>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <select type="button" class="btn btn-outline-secondary" formControlName="phone_code"
                                    [(ngModel)]="globals.dataUser.phone.code">
                                    <option [value]="item.dial_code"
                                        *ngFor="let item of globals.listCountries; trackBy: trackByFn">
                                        {{item.code}} {{item.dial_code}}</option>
                                </select>
                            </div>
                            <input type="text" class="form-control" formControlName="phone_number"
                                placeholder="xxx xxxx xxx" [(ngModel)]="globals.dataUser.phone.number">
                        </div>
                    </div>
                    <div class="form-group">
                        <span>{{'body.text6' | translate}}</span>
                        <select class="form-select" formControlName="country"
                            [(ngModel)]="globals.dataUser.address.country">
                            <option value="0"> {{'authentication.text41' | translate}} </option>
                            <option [value]="item.name" *ngFor="let item of globals.listCountries; trackBy: trackByFn">
                                {{item.name}} </option>
                        </select>
                    </div>
                    <div class="form-check">
                        <label class="form-check-label">
                            <input type="checkbox" class="form-check-input" (change)="checkValue($event)"
                                [checked]="remember === 'true'"> {{'body.text7' | translate}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="modal.close('Close click')">{{'body.text8' |
                translate}}</button>
            <button type="submit" class="btn btn-info">{{'chat.text4' | translate}} dddd</button>
        </div>
    </form>
</ng-template>
